<template>
  <div class="">
    <!--  -->
    <div v-runshow class="flex j_c_b top_com" style="margin: 0.6rem 0">
      <div style="width: 48%; box-sizing: border-box">
        <div class="new_title flex a_i_c is_title" style="">
          <span style=""></span> 我们是谁
        </div>
        <div class="flex j_c_c">
          <div class="tips" style="margin: 0 auto"></div>
        </div>
        <div class="is_desc" style="">
          <!-- 华信互联，致力于科技健康产业，
          以一体化为理念、数据智能为驱动，打造健康体检、智慧慢病管理、智慧随访、全程满意度调查管理、健康宣教、智能设备等一体化的解决方案和产品矩阵，与行业内众多知名专家联手打造知识库和干预方案，全面打通“检”与“诊”环节，构建以患者为中心的数智化全病程管理体系，在医疗机构、患者、智能设备之间建立高质量的数据联结。 -->
          <p>
            <strong>华信互联</strong
            >，聚焦“互联网+”诊后疾病管理，致力打造全场景疾病管理与智慧体检<strong>一体化解决方案</strong>。凭借领先的产品矩阵和丰富的医学知识库，协助医院和基层社区，构建一站式的精细化慢病服务网络，助力医院高质量发展和数字化转型。
          </p>
          <p>
            公司通过了ISO22301业务连续性等资质认证；一系列产品均通过了国家软件测评中心的测评认证。
          </p>
          <p>
            我司凭借资深专业团队和全新的业务模式、卓越的产品体验，携手众多合作伙伴，<strong>已协助几十家医院建设健康管理中心</strong>，广受业界好评。
          </p>
        </div>
      </div>
      <div class="" style="width: 50%; font-size: 0px">
        <img
          style="width: 100%"
          v-runcentershow
          :src="require('@/assets/aboutUs/isimg.png')"
          alt=""
        />
      </div>
    </div>
    <!--  -->
    <div v-runshow class="partTwo flex flex_d a_i_c j_c_c">
      <div class="is_2_title" style="">企业文化</div>
      <div class="tips"></div>
      <div class="is_2_desc" style="">
        <div>
          以客户为中心，秉承生态主义，尊重知识，让员工持续成长，持续价值创造，以科技的力量让生命更美好。
        </div>
      </div>
    </div>

    <!--  -->
    <div style="text-align: center">
      <div v-runshow class="flex flex_d j_c_c a_i_c">
        <div class="is_3_title" style="">我们的价值观</div>
        <div class="tips"></div>
      </div>
      <div class="pc_" v-runshow>
        <div class="item_">
          <div>
            <svg
              width="100%"
              height="100%"
              viewBox="0 0 93 95"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1379_35011)">
                <path
                  d="M5.04943 94.7093L2.60971 75.1916C1.99978 72.1419 4.4395 69.0923 7.48915 67.8724L29.4466 60.5533L36.7657 92.8795L41.0352 70.3122L36.7657 62.3831H42.865H48.9643L45.3047 69.7022L49.5743 92.2696L56.8934 59.9434L78.8509 67.2625C81.2906 67.8724 83.1204 70.3122 83.7303 72.7519"
                  stroke="#61646B"
                  stroke-width="3"
                  stroke-miterlimit="10"
                  stroke-linejoin="round"
                />
                <path
                  d="M41.6448 2.60938C35.5455 2.60938 28.2264 4.43916 24.5668 9.92852C22.1271 13.5881 21.5171 17.8576 22.1271 22.1271C22.1271 23.347 22.1271 24.5668 22.737 25.1767C20.2973 26.3966 20.2973 27.6165 20.9072 34.3257C22.1271 40.425 25.7866 39.815 25.7866 39.815C28.8363 55.0633 39.815 56.8931 42.8647 56.8931C45.9143 56.8931 56.893 55.0633 59.9427 39.815C59.9427 39.815 63.6022 40.425 64.8221 34.3257C66.042 28.2264 65.432 26.3966 62.9923 25.7867L63.6022 22.737C63.6022 20.2973 63.6022 17.8576 62.9923 15.4179C61.1625 6.26895 52.6235 3.2193 44.6944 3.2193C43.4746 2.60938 42.8647 2.60938 41.6448 2.60938Z"
                  stroke="#61646B"
                  stroke-width="3"
                  stroke-miterlimit="10"
                />
                <path
                  d="M84.9454 84.3392C83.1157 86.169 74.5766 94.708 74.5766 94.708C74.5766 94.708 74.5766 94.708 73.9667 94.708C73.9667 94.708 73.9667 94.708 73.3568 94.708L62.988 84.3392C61.7681 83.1193 61.1582 81.2896 61.1582 79.4598C61.1582 75.8002 64.2078 72.1406 68.4774 72.1406C70.9171 72.1406 73.3568 73.3605 74.5766 75.1903C75.7965 73.3605 78.2362 72.1406 80.6759 72.1406C84.3355 72.1406 87.9951 75.1903 87.9951 79.4598C87.3852 81.2896 86.1653 83.1193 84.9454 84.3392Z"
                  stroke="#61646B"
                  stroke-width="3"
                  stroke-miterlimit="10"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_1379_35011">
                  <rect width="93" height="95" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>

          <div>成就客户</div>
        </div>
        <div class="item_">
          <div>
            <svg
              width="100%"
              height="100%"
              viewBox="0 0 89 96"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M58.3326 73.2403C58.3326 65.787 60.0526 63.4936 60.6259 62.9203C66.3592 58.3336 69.7992 51.4536 69.7992 43.427C69.7992 36.547 66.9326 30.2403 62.3459 25.0803C57.1859 20.4936 50.8792 18.2003 43.9992 17.627C37.1192 18.2003 30.8126 20.4936 25.6526 25.0803C21.0659 30.2403 18.1992 36.547 18.1992 43.427C18.1992 50.8803 21.6392 58.3336 27.3726 62.9203C27.9459 64.067 29.6659 65.787 29.6659 73.2403"
                stroke="#61646B"
                stroke-width="3"
                stroke-miterlimit="10"
                stroke-linejoin="round"
              />
              <path
                d="M29.666 78.9727H58.3327"
                stroke="#61646B"
                stroke-width="3"
                stroke-miterlimit="10"
                stroke-linejoin="round"
              />
              <path
                d="M29.666 87H58.3327"
                stroke="#61646B"
                stroke-width="3"
                stroke-miterlimit="10"
                stroke-linejoin="round"
              />
              <path
                d="M37.1191 93.3066H50.8791"
                stroke="#61646B"
                stroke-width="3"
                stroke-miterlimit="10"
                stroke-linejoin="round"
              />
              <path
                d="M73.2402 56.0391L87.0002 61.7724"
                stroke="#61646B"
                stroke-width="3"
                stroke-miterlimit="10"
                stroke-linejoin="round"
              />
              <path
                d="M1.57422 26.2266L15.3342 31.9599"
                stroke="#61646B"
                stroke-width="3"
                stroke-miterlimit="10"
                stroke-linejoin="round"
              />
              <path
                d="M15.3342 56.0391L1.57422 61.7724"
                stroke="#61646B"
                stroke-width="3"
                stroke-miterlimit="10"
                stroke-linejoin="round"
              />
              <path
                d="M61.7724 1.57422L56.0391 14.7609"
                stroke="#61646B"
                stroke-width="3"
                stroke-miterlimit="10"
                stroke-linejoin="round"
              />
              <path
                d="M26.2266 1.57422L31.9599 14.7609"
                stroke="#61646B"
                stroke-width="3"
                stroke-miterlimit="10"
                stroke-linejoin="round"
              />
              <path
                d="M87.0002 26.2266L73.2402 31.9599"
                stroke="#61646B"
                stroke-width="3"
                stroke-miterlimit="10"
                stroke-linejoin="round"
              />
              <path
                d="M48.5876 73.2407C48.5876 73.2407 48.5876 50.3074 48.5876 45.7207C48.5876 41.1341 50.3076 39.4141 53.1743 39.4141C56.0409 39.4141 57.1876 42.2807 57.1876 43.4274C57.1876 44.5741 56.6143 47.4407 53.7476 47.4407C51.4543 47.4407 38.2676 47.4407 35.9743 47.4407C33.1076 47.4407 31.9609 45.1474 31.9609 43.4274C31.9609 41.7074 33.1076 39.4141 35.9743 39.4141C39.4143 39.4141 40.5609 41.1341 40.5609 45.7207C40.5609 50.3074 40.5609 73.2407 40.5609 73.2407"
                stroke="#61646B"
                stroke-width="3"
                stroke-miterlimit="10"
                stroke-linejoin="round"
              />
            </svg>
          </div>

          <div>持续创新</div>
        </div>
        <div class="item_">
          <div>
            <svg
              width="100%"
              height="100%"
              viewBox="0 0 90 77"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M87.4109 23.6472C87.4109 23.0621 87.4109 23.0621 86.8259 22.4771L64.0096 3.17097C63.4245 2.58594 62.8395 2.58594 62.2545 2.58594H27.7374C27.1524 2.58594 26.5674 2.58594 25.9823 3.17097L3.75105 22.4771C3.75105 22.4771 3.16602 23.0621 3.16602 23.6472C3.16602 24.2322 3.16602 24.2322 3.75105 24.8172L44.1184 73.375C44.7034 73.9601 44.7034 73.9601 45.8735 73.9601C46.4585 73.9601 47.0436 73.9601 47.6286 73.375L86.8259 25.2192C86.8259 25.2192 87.4109 24.2322 87.4109 23.6472Z"
                stroke="#61646B"
                stroke-width="3"
                stroke-miterlimit="10"
                stroke-linejoin="round"
              />
              <path
                d="M51.1445 2.58594L64.6003 23.6472"
                stroke="#61646B"
                stroke-width="3"
                stroke-miterlimit="10"
                stroke-linejoin="round"
              />
              <path
                d="M25.4004 23.6472L39.4412 2.58594"
                stroke="#61646B"
                stroke-width="3"
                stroke-miterlimit="10"
                stroke-linejoin="round"
              />
              <path
                d="M64.5977 23.6484L45.2915 73.9614L25.4004 23.6484"
                stroke="#61646B"
                stroke-width="3"
                stroke-miterlimit="10"
                stroke-linejoin="round"
              />
              <path
                d="M87.412 23.6484H2.58203"
                stroke="#61646B"
                stroke-width="3"
                stroke-miterlimit="10"
                stroke-linejoin="round"
              />
            </svg>
          </div>

          <div>诚信正直</div>
        </div>
        <div class="item_">
          <div>
            <svg
              width="100%"
              height="100%"
              viewBox="0 0 71 91"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22.9335 43.1875L2.6835 70.75C0.995999 73.5625 2.6835 76.9375 6.0585 76.9375H17.3085"
                stroke="#61646B"
                stroke-width="3"
                stroke-miterlimit="10"
                stroke-linejoin="round"
              />
              <path
                d="M47.6816 43.1875L67.9316 70.75C69.6191 73.5625 67.9316 76.9375 64.5566 76.9375H53.3066"
                stroke="#61646B"
                stroke-width="3"
                stroke-miterlimit="10"
                stroke-linejoin="round"
              />
              <path
                d="M35.3047 65.6875H47.6797C47.6797 65.6875 47.6797 33.625 47.6797 21.25C47.6797 8.875 40.3672 1.5625 35.3047 1.5625C30.2422 1.5625 22.9297 8.875 22.9297 21.25C22.9297 33.625 22.9297 65.6875 22.9297 65.6875H35.3047Z"
                stroke="#61646B"
                stroke-width="3"
                stroke-miterlimit="10"
                stroke-linejoin="round"
              />
              <path
                d="M47.6805 65.6875L53.3055 76.9375C54.4305 79.1875 52.743 82.5625 49.9305 82.5625H35.3055H20.6805C17.868 82.5625 16.1805 79.75 17.3055 76.9375L22.9305 65.6875"
                stroke="#61646B"
                stroke-width="3"
                stroke-miterlimit="10"
                stroke-linejoin="round"
              />
              <path
                d="M18.9961 73.5625H51.6211"
                stroke="#61646B"
                stroke-width="3"
                stroke-miterlimit="10"
                stroke-linejoin="round"
              />
              <path
                d="M25.1816 82V88.75"
                stroke="#61646B"
                stroke-width="3"
                stroke-miterlimit="10"
                stroke-linejoin="round"
              />
              <path
                d="M45.4297 82V88.75"
                stroke="#61646B"
                stroke-width="3"
                stroke-miterlimit="10"
                stroke-linejoin="round"
              />
              <path
                d="M35.3105 91V73.5625"
                stroke="#61646B"
                stroke-width="3"
                stroke-miterlimit="10"
                stroke-linejoin="round"
              />
              <path
                d="M40.9355 16.752C40.9355 11.127 37.5605 8.31445 35.3105 8.31445C33.0605 8.31445 29.6855 11.6895 29.6855 16.752"
                stroke="#61646B"
                stroke-width="3"
                stroke-miterlimit="10"
                stroke-linejoin="round"
              />
            </svg>
          </div>

          <div>追求卓越</div>
        </div>
      </div>
      <!-- mobile -->
      <div v-runshow class="mobile_">
        <div class="block">
          <div class="is_mobile_swiper p_r" style="height: 58vw">
            <div class="swiper-wrapper" style="height: 100%">
              <div class="swiper-slide" style="width: 100vw">
                <div class="flex">
                  <div class="item_">
                    <div>
                      <svg
                        width="100%"
                        height="100%"
                        viewBox="0 0 93 95"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_1379_35011)">
                          <path
                            d="M5.04943 94.7093L2.60971 75.1916C1.99978 72.1419 4.4395 69.0923 7.48915 67.8724L29.4466 60.5533L36.7657 92.8795L41.0352 70.3122L36.7657 62.3831H42.865H48.9643L45.3047 69.7022L49.5743 92.2696L56.8934 59.9434L78.8509 67.2625C81.2906 67.8724 83.1204 70.3122 83.7303 72.7519"
                            stroke="#61646B"
                            stroke-width="3"
                            stroke-miterlimit="10"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M41.6448 2.60938C35.5455 2.60938 28.2264 4.43916 24.5668 9.92852C22.1271 13.5881 21.5171 17.8576 22.1271 22.1271C22.1271 23.347 22.1271 24.5668 22.737 25.1767C20.2973 26.3966 20.2973 27.6165 20.9072 34.3257C22.1271 40.425 25.7866 39.815 25.7866 39.815C28.8363 55.0633 39.815 56.8931 42.8647 56.8931C45.9143 56.8931 56.893 55.0633 59.9427 39.815C59.9427 39.815 63.6022 40.425 64.8221 34.3257C66.042 28.2264 65.432 26.3966 62.9923 25.7867L63.6022 22.737C63.6022 20.2973 63.6022 17.8576 62.9923 15.4179C61.1625 6.26895 52.6235 3.2193 44.6944 3.2193C43.4746 2.60938 42.8647 2.60938 41.6448 2.60938Z"
                            stroke="#61646B"
                            stroke-width="3"
                            stroke-miterlimit="10"
                          />
                          <path
                            d="M84.9454 84.3392C83.1157 86.169 74.5766 94.708 74.5766 94.708C74.5766 94.708 74.5766 94.708 73.9667 94.708C73.9667 94.708 73.9667 94.708 73.3568 94.708L62.988 84.3392C61.7681 83.1193 61.1582 81.2896 61.1582 79.4598C61.1582 75.8002 64.2078 72.1406 68.4774 72.1406C70.9171 72.1406 73.3568 73.3605 74.5766 75.1903C75.7965 73.3605 78.2362 72.1406 80.6759 72.1406C84.3355 72.1406 87.9951 75.1903 87.9951 79.4598C87.3852 81.2896 86.1653 83.1193 84.9454 84.3392Z"
                            stroke="#61646B"
                            stroke-width="3"
                            stroke-miterlimit="10"
                            stroke-linejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1379_35011">
                            <rect width="93" height="95" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>

                    <div>成就客户</div>
                  </div>
                  <div class="item_">
                    <div>
                      <svg
                        width="100%"
                        height="100%"
                        viewBox="0 0 89 96"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M58.3326 73.2403C58.3326 65.787 60.0526 63.4936 60.6259 62.9203C66.3592 58.3336 69.7992 51.4536 69.7992 43.427C69.7992 36.547 66.9326 30.2403 62.3459 25.0803C57.1859 20.4936 50.8792 18.2003 43.9992 17.627C37.1192 18.2003 30.8126 20.4936 25.6526 25.0803C21.0659 30.2403 18.1992 36.547 18.1992 43.427C18.1992 50.8803 21.6392 58.3336 27.3726 62.9203C27.9459 64.067 29.6659 65.787 29.6659 73.2403"
                          stroke="#61646B"
                          stroke-width="3"
                          stroke-miterlimit="10"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M29.666 78.9727H58.3327"
                          stroke="#61646B"
                          stroke-width="3"
                          stroke-miterlimit="10"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M29.666 87H58.3327"
                          stroke="#61646B"
                          stroke-width="3"
                          stroke-miterlimit="10"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M37.1191 93.3066H50.8791"
                          stroke="#61646B"
                          stroke-width="3"
                          stroke-miterlimit="10"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M73.2402 56.0391L87.0002 61.7724"
                          stroke="#61646B"
                          stroke-width="3"
                          stroke-miterlimit="10"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M1.57422 26.2266L15.3342 31.9599"
                          stroke="#61646B"
                          stroke-width="3"
                          stroke-miterlimit="10"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M15.3342 56.0391L1.57422 61.7724"
                          stroke="#61646B"
                          stroke-width="3"
                          stroke-miterlimit="10"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M61.7724 1.57422L56.0391 14.7609"
                          stroke="#61646B"
                          stroke-width="3"
                          stroke-miterlimit="10"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M26.2266 1.57422L31.9599 14.7609"
                          stroke="#61646B"
                          stroke-width="3"
                          stroke-miterlimit="10"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M87.0002 26.2266L73.2402 31.9599"
                          stroke="#61646B"
                          stroke-width="3"
                          stroke-miterlimit="10"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M48.5876 73.2407C48.5876 73.2407 48.5876 50.3074 48.5876 45.7207C48.5876 41.1341 50.3076 39.4141 53.1743 39.4141C56.0409 39.4141 57.1876 42.2807 57.1876 43.4274C57.1876 44.5741 56.6143 47.4407 53.7476 47.4407C51.4543 47.4407 38.2676 47.4407 35.9743 47.4407C33.1076 47.4407 31.9609 45.1474 31.9609 43.4274C31.9609 41.7074 33.1076 39.4141 35.9743 39.4141C39.4143 39.4141 40.5609 41.1341 40.5609 45.7207C40.5609 50.3074 40.5609 73.2407 40.5609 73.2407"
                          stroke="#61646B"
                          stroke-width="3"
                          stroke-miterlimit="10"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>

                    <div>持续创新</div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide" style="width: 100vw">
                <div class="flex">
                  <div class="item_">
                    <div>
                      <svg
                        width="100%"
                        height="100%"
                        viewBox="0 0 90 77"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M87.4109 23.6472C87.4109 23.0621 87.4109 23.0621 86.8259 22.4771L64.0096 3.17097C63.4245 2.58594 62.8395 2.58594 62.2545 2.58594H27.7374C27.1524 2.58594 26.5674 2.58594 25.9823 3.17097L3.75105 22.4771C3.75105 22.4771 3.16602 23.0621 3.16602 23.6472C3.16602 24.2322 3.16602 24.2322 3.75105 24.8172L44.1184 73.375C44.7034 73.9601 44.7034 73.9601 45.8735 73.9601C46.4585 73.9601 47.0436 73.9601 47.6286 73.375L86.8259 25.2192C86.8259 25.2192 87.4109 24.2322 87.4109 23.6472Z"
                          stroke="#61646B"
                          stroke-width="3"
                          stroke-miterlimit="10"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M51.1445 2.58594L64.6003 23.6472"
                          stroke="#61646B"
                          stroke-width="3"
                          stroke-miterlimit="10"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M25.4004 23.6472L39.4412 2.58594"
                          stroke="#61646B"
                          stroke-width="3"
                          stroke-miterlimit="10"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M64.5977 23.6484L45.2915 73.9614L25.4004 23.6484"
                          stroke="#61646B"
                          stroke-width="3"
                          stroke-miterlimit="10"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M87.412 23.6484H2.58203"
                          stroke="#61646B"
                          stroke-width="3"
                          stroke-miterlimit="10"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>

                    <div>诚信正直</div>
                  </div>
                  <div class="item_">
                    <div>
                      <svg
                        width="100%"
                        height="100%"
                        viewBox="0 0 71 91"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M22.9335 43.1875L2.6835 70.75C0.995999 73.5625 2.6835 76.9375 6.0585 76.9375H17.3085"
                          stroke="#61646B"
                          stroke-width="3"
                          stroke-miterlimit="10"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M47.6816 43.1875L67.9316 70.75C69.6191 73.5625 67.9316 76.9375 64.5566 76.9375H53.3066"
                          stroke="#61646B"
                          stroke-width="3"
                          stroke-miterlimit="10"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M35.3047 65.6875H47.6797C47.6797 65.6875 47.6797 33.625 47.6797 21.25C47.6797 8.875 40.3672 1.5625 35.3047 1.5625C30.2422 1.5625 22.9297 8.875 22.9297 21.25C22.9297 33.625 22.9297 65.6875 22.9297 65.6875H35.3047Z"
                          stroke="#61646B"
                          stroke-width="3"
                          stroke-miterlimit="10"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M47.6805 65.6875L53.3055 76.9375C54.4305 79.1875 52.743 82.5625 49.9305 82.5625H35.3055H20.6805C17.868 82.5625 16.1805 79.75 17.3055 76.9375L22.9305 65.6875"
                          stroke="#61646B"
                          stroke-width="3"
                          stroke-miterlimit="10"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M18.9961 73.5625H51.6211"
                          stroke="#61646B"
                          stroke-width="3"
                          stroke-miterlimit="10"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M25.1816 82V88.75"
                          stroke="#61646B"
                          stroke-width="3"
                          stroke-miterlimit="10"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M45.4297 82V88.75"
                          stroke="#61646B"
                          stroke-width="3"
                          stroke-miterlimit="10"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M35.3105 91V73.5625"
                          stroke="#61646B"
                          stroke-width="3"
                          stroke-miterlimit="10"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M40.9355 16.752C40.9355 11.127 37.5605 8.31445 35.3105 8.31445C33.0605 8.31445 29.6855 11.6895 29.6855 16.752"
                          stroke="#61646B"
                          stroke-width="3"
                          stroke-miterlimit="10"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>

                    <div>追求卓越</div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 如果需要分页器 -->
            <div class="is_mobile_swiper_agination swiper-pagination"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      card: [
        {
          title: "海纳计划训练营",
          desc: "产品沉浸式学习、案例多维度讲解、全场景模拟演练",
        },
        {
          title: "专项活动指导",
          desc: "制定区域专项事项（场景式推介会、品鉴会、技术沙龙等）",
        },
        {
          title: "销售队伍",
          desc: "协助下游伙伴建立专业销售队伍",
        },
        {
          title: "商机挖掘",
          desc: "共同挖掘商业机会，联合制定商业计划",
        },
        {
          title: "资金支持",
          desc: "专项资金支持",
        },
        {
          title: "营销支持",
          desc: "全程营销工具支持",
        },
        {
          title: "招投标支持",
          desc: "招投标过程支持",
        },
        {
          title: "签约授权",
          desc: "协议证书签约，授权牌发放",
        },
      ],
      list: [
        {
          name: "战略级",
          desc: "产品整合+渠道代理型，伙伴双方的产品在技术和渠道方面均可以深度整合，形成创新型的解决方案，并通过双方的渠道相互拉动销售",
          icon: `<svg style="width:100%;height:100%;" viewBox="0 0 87 98" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 35.4453H14.3333" stroke="#61646B" stroke-width="3" stroke-miterlimit="10"/>
                    <path d="M7.50781 27.9375V42.9534" stroke="#61646B" stroke-width="3" stroke-miterlimit="10"/>
                    <path d="M60.7457 42.2695L45.7298 56.6029L31.3965 42.2695" stroke="#1AA858" stroke-width="3" stroke-miterlimit="10"/>
                    <path d="M7.50781 22.4763C7.50781 19.7462 7.50781 18.3811 7.50781 18.3811C34.1269 17.6986 45.73 3.36523 45.73 3.36523C45.73 3.36523 57.3332 17.6986 84.6348 18.3811C84.6348 18.3811 84.6348 46.3652 84.6348 54.5557C84.6348 62.7462 70.984 90.7303 45.73 95.5081C20.4761 90.7303 7.50781 62.7462 7.50781 54.5557C7.50781 51.8255 7.50781 50.4605 7.50781 49.0954" stroke="#61646B" stroke-width="3" stroke-miterlimit="10"/>
                    </svg>
                    `,
        },
        {
          name: "钻石级",
          desc: "产品整合+渠道代理型，伙伴双方的产品在技术和渠道方面均可以深度整合，形成创新型的解决方案，并通过双方的渠道相互拉动销售",
          icon: `<svg style="width:100%;height:100%;" viewBox="0 0 99 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M35.2246 9.27148H48.6413" stroke="#61646B" stroke-width="3" stroke-miterlimit="10"/>
                    <path d="M41.6133 2.24414V15.6608" stroke="#61646B" stroke-width="3" stroke-miterlimit="10"/>
                    <path d="M28.8333 9.27148H21.8056L2 36.1048L49.2778 92.327L96.5556 36.1048L76.75 9.27148H54.3889" stroke="#61646B" stroke-width="3" stroke-miterlimit="10"/>
                    <path d="M62.6968 34.1875L49.2802 47.6042L35.2246 34.1875" stroke="#61646B" stroke-width="3" stroke-miterlimit="10"/>
                    </svg>
                    `,
        },
        {
          name: "白金级",
          desc: "产品整合+渠道代理型，伙伴双方的产品在技术和渠道方面均可以深度整合，形成创新型的解决方案，并通过双方的渠道相互拉动销售",
          icon: `<svg style="width:100%;height:100%;" viewBox="0 0 92 75" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M31.2012 6.64453H43.8857" stroke="#61646B" stroke-width="3" stroke-miterlimit="10"/>
                    <path d="M37.2422 0V12.6846" stroke="#61646B" stroke-width="3" stroke-miterlimit="10"/>
                    <path d="M58.9868 33.2207L46.3023 45.9053L33.0137 33.2207" stroke="#61646B" stroke-width="3" stroke-miterlimit="10"/>
                    <path d="M51.1352 6.64453H72.8801L89.7929 73.0875H2.20898L19.7258 6.64453H25.162" stroke="#61646B" stroke-width="3" stroke-miterlimit="10"/>
                    </svg>
                    `,
        },
      ],
    };
  },
  mounted() {
    var mySwiper = new Swiper(".is_mobile_swiper", {
      direction: "horizontal", // 垂直切换选项
      loop: true, // 循环模式选项
      // 如果需要分页器
      pagination: {
        el: ".is_mobile_swiper_agination",
      },
    });
  },
};
</script>

<style scoped>
.is_mobile_swiper {
  overflow: hidden;
}
.is_3_title {
  font-size: 0.38rem;
  line-height: 0.53rem;
  margin-top: 1.2rem;
}
.is_title {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 900;
  font-size: 0.38rem;
  line-height: 120%;
  /* identical to box height */
  color: #21252e;
}
.is_title span {
  display: inline-block;
  background: #1aa858;
  width: 0.03rem;
  height: 0.38rem;
  margin-right: 6px;
}
.mobile_ {
  height: 0;
  overflow: hidden;
}
.box {
  padding: 0;
  box-sizing: border-box;
}
.pc_ {
  display: flex;
  justify-content: center;
  margin: 0.72rem 0 1.2rem 0;
}
.item_ {
  width: calc((100vw - 160px) / 4);
  height: calc((100vw - 160px) / 4);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 800;
  border: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-left: none;
  transition: all 1s ease !important;
}
.item_ > div:nth-child(1) {
  width: 0.7rem;
  height: 0.7rem;
  margin-bottom: 0.18rem;
}
.item_:hover {
  background: #1aa858;
  /* color: #fff; */
  cursor: pointer;
}
.item_:hover svg path {
  stroke: white;
}
.item_ svg path {
  transition: all 0.4s ease !important;
}
.item_:hover > div {
  color: #fff;
}
.item_ > div {
  transition: all 0.4s ease !important;
  font-size: 0.24rem;
}
.item_:nth-child(1) {
  border-left: 1px solid #ccc;
}
.item_ svg {
  margin-bottom: 0.2rem;
}
.tips {
  width: 80px;
  height: 3px;
  background: #1aa858;
  margin: 10px;
}
.partTwo {
  width: 100vw;
  /* margin-left: -1.9rem; */
  background-color: #333;
  min-height: 300px;
  color: #fff;
  font-size: 14px;
  line-height: 180%;
  padding: 1.2rem 20px;
  box-sizing: border-box;
  background-image: url("../../assets/aboutUs/bg.png");
  background-repeat: no-repeat;
  background-position: center;
  /* background-size: center; */
  background-size: cover;
}
.flex_1 {
  flex: 1;
}
textarea,
input {
  background: rgba(0, 0, 0, 0);
  flex: 1;
  outline: none;
  border: none;
  padding: 10px;
  box-sizing: border-box;
}
.item_row {
  align-items: flex-start;
  padding: 0 10px;
  margin-bottom: 20px;
  background: #f7f7fa;
  border-radius: 6px;
  display: flex;
  width: 100%;
}
.item_row > span {
  color: red;
  margin-top: 8px;
  font-size: 14px;
}
.top_com {
  padding: 0px 1rem;
}
/*  */
.top_com .tips {
  display: none;
}
.clear {
  clear: both;
}

.tips {
  width: 70px;
  height: 3px;
  background: #1aa858;
}
.is_4_com_swiper-pagination >>> .swiper-pagination-bullet {
  background: #1aa858;
}
/* .p_box {
  padding: 40px 1.9rem !important;
} */
@media screen and (max-width: 1024px) {
  .p_box {
    padding: 0px 20px 40px 20px !important;
  }
  .mobile_ {
    height: auto;
    overflow: visible;
    padding: 0 20px 40px 20px;
  }
  .mobile_ .item_ {
    width: 48vw;
    height: 48vw;
  }
  .mobile_ .item_ > div:nth-child(1) {
    width: 0.7rem;
    height: 0.7rem;
    margin-bottom: 0.18rem;
  }
  .pc_ {
    display: none !important;
  }
  .partTwo {
    /* margin-left: -20px; */
  }
  .lastcom .right_ctx_box {
    width: 100%;
    padding: 0;
  }
  .lastcom > div:nth-child(1) {
    width: calc(100vw - 60px);
    flex: none;
  }
  .right_ctx {
    width: 100%;
    margin: 20px 0 !important;
    padding: 20px;
  }
  .top_com {
    flex-wrap: wrap;
    padding: 0 20px;
    /* flex-direction: column; */
    /* flex-direction: column-reverse; */
  }
  .new_title {
    text-align: center;
    /* width: 100%; */
    box-sizing: border-box;
    justify-content: center;
  }
  .new_title span {
    display: none;
  }
  .top_com .is_title span {
    display: none !important;
  }
  .top_com .is_title {
    margin: 20px 0;
    margin-bottom: 10px;
    text-align: center;
    font-size: 18px;
    padding: 0 40px;
  }
  .top_com .tips {
    display: inline-block;
    margin-bottom: 20px;
  }
  .top_com > div {
    width: 100% !important;
    padding-right: 0 !important;
    margin-bottom: 18px;
  }
  .top_com > img {
    width: 100% !important;
  }
  .box {
    padding: 20px;
  }
  .is_3_title {
    margin-top: 0.6rem;
  }
}
.is_desc {
  line-height: 180%;
  color: #888;
  font-size: 0.24rem;
  margin-top: 10px;
}
.is_desc > p {
  margin-bottom: 10px;
}
.is_desc > p > strong {
  color: #414143;
}

.is_2_title {
  font-size: 0.38rem;
  line-height: 0.53rem;
}
.is_2_desc {
  font-size: 0.24rem;
  line-height: 0.38rem;
  text-align: center;
  margin-top: 0.72rem;
}
.item {
  font-size: 0.28rem;
  line-height: 0.39rem;
}
/* @media screen and (min-width:1910px) {
    .is_desc{
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 36px;
        color: #61646B;
    }

    .is_2_title{
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 38px;
        line-height: 53px;
    }

    .is_2_desc{
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 34px;
        text-align: center;
    }
    .partTwo{
        padding: 150px 0;
        margin-left: -160px;
        min-height: 4.61rem;
    }
    .top_com{
        padding: 40px 0;
    }
    .p_box{
        padding: 40px 160px!important;
    }
    .is_3_title{
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 38px;
        line-height: 53px;
        margin-top: 120px;   
        margin-bottom: 25px;
    }
    .item_{
        margin-top: 60px;
        width: calc((100vw - 320px) /4);
        height: calc((100vw - 320px) /4);

        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 900;
        font-size: 28px;
        line-height: 39px;
    }
    .item_ svg{
        width: 90px;
        height: 90px;
    }
} */
</style>
